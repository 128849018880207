import Image from 'next/image'
import Recently_added_dropdown from '../dropdown/recently_added_dropdown'
import Link from 'next/link'
import React from 'react'
import { socialIcons } from '../../data/footer_data'
import { getUrl } from '../../lib/urlHandler'

const last7DaysRanks = [
  {
    id: 1,
    text: 'Last 7 Days',
  },
  {
    id: 2,
    text: 'Last 14 Days',
  },
  {
    id: 3,
    text: 'Last 30 Days',
  },
  {
    id: 4,
    text: 'Last 60 Days',
  },
  {
    id: 5,
    text: 'Last 90 Days',
  },
  {
    id: 6,
    text: 'Last Year',
  },
  {
    id: 7,
    text: 'All Time',
  },
];

const Rankings = ({ contestants, ...rest }) => {
  return (
    <section className="relative mt-16 lg:mt-24 lg:pb-24 pb-24">
      <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
        <Image
          src="/images/gradient_light.jpg"
          layout="fill"
          alt="gradient"
          className="h-full w-full"
        />
      </picture>
      <div className="container">
        <h1 className="font-display text-jacarta-700 py-16 text-center text-4xl font-medium dark:text-white">
          Rankings
        </h1>

        {/* <!-- Filters --> */}
        {/*<div className="mb-8 flex flex-wrap items-center justify-between">*/}
        {/*  <div className="flex flex-wrap items-center">*/}
        {/*    <input*/}
        {/*      type="search"*/}
        {/*      className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-[0.6875rem] px-4 pl-10 bg-white"*/}
        {/*      placeholder="Search"*/}
        {/*    />*/}
        {/*  </div>*/}

        {/*  /!* last 7 days *!/*/}
        {/*  <Recently_added_dropdown data={last7DaysRanks} dropdownFor="last7Days-ranks" />*/}

        {/*  /!* <!--  --> *!/*/}
        {/*</div>*/}
        {/* <!-- end filters --> */}

        {/* <!-- Table --> */}
        <div className="scrollbar-custom overflow-x-auto">
          <div
            role="table"
            className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 lg:rounded-2lg w-full min-w-[736px] border bg-white text-sm dark:text-white"
          >
            <div className="dark:bg-jacarta-600 bg-jacarta-50 rounded-t-2lg flex" role="row">
              <div className="w-[50%] py-3 px-4" role="columnheader">
									<span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
										Participante
									</span>
              </div>
              <div className="w-[12%] py-3 px-4" role="columnheader">
									<span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
										Votos
									</span>
              </div>
              <div className="w-[25%] py-3 px-4" role="columnheader">
									<span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
										Restaurante
									</span>
              </div>
              <div className="w-full py-3 px-4" role="columnheader">
									<span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
										Redes
									</span>
              </div>
            </div>
            {contestants && contestants.length > 0 && contestants.map((item) => {
              const {
                slug,
                burgerThumbnailUrl,
                burgerName,
                restaurantName,
                votes,
                social
              } = item;
              const itemLink = `/burger/${slug}`;
              // console.log(item.category.art);
              return (
                <Link href={itemLink} key={slug}>
                  <div className="flex transition-shadow hover:shadow-lg cursor-pointer" role="row">
                    <div
                      className="dark:border-jacarta-600 border-jacarta-100 flex w-[50%] items-center border-t py-4 px-4"
                      role="cell"
                    >
                      <figure className="relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12">
                        {/* <img src={image} alt={title} className="rounded-2lg" loading="lazy" /> */}
                        <Image
                          src={burgerThumbnailUrl?.length > 0 ? burgerThumbnailUrl : '/images/gradient_light.jpg'}
                          alt={burgerName}
                          height={32}
                          width={32}
                          layout="responsive"
                          objectFit="contain"
                          className="rounded-2lg"
                        />

                        {/*{imgUrl && (*/}
                        {/*  <div*/}
                        {/*    className="dark:border-jacarta-600 bg-green absolute -right-2 -bottom-1 flex h-6 w-6 items-center justify-center rounded-full border-2 border-white"*/}
                        {/*    data-tippy-content="Verified Collection"*/}
                        {/*  >*/}
                        {/*    <svg*/}
                        {/*      xmlns="http://www.w3.org/2000/svg"*/}
                        {/*      viewBox="0 0 24 24"*/}
                        {/*      width="24"*/}
                        {/*      height="24"*/}
                        {/*      className="h-[.875rem] w-[.875rem] fill-white"*/}
                        {/*    >*/}
                        {/*      <path fill="none" d="M0 0h24v24H0z"></path>*/}
                        {/*      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>*/}
                        {/*    </svg>*/}
                        {/*  </div>*/}
                        {/*)}*/}
                      </figure>
                      <span className="font-display text-jacarta-700 text-sm font-semibold dark:text-white">
													{burgerName}
												</span>
                    </div>
                    <div
                      className="dark:border-jacarta-600 border-jacarta-100 flex w-[12%] items-center whitespace-nowrap border-t py-4 px-4"
                      role="cell"
                    >
                      <span className="text-sm font-medium tracking-tight">{votes}</span>
                    </div>
                    <div
                      className="dark:border-jacarta-600 border-jacarta-100 flex w-[25%] items-center border-t py-4 px-4"
                      role="cell"
                    >
                      <span>{restaurantName}</span>
                    </div>
                    <div
                      className="dark:border-jacarta-600 border-jacarta-100 flex w-full items-center border-t py-4 px-4"
                      role="cell"
                    >
                      <div className="flex space-x-5">
                        {social && Object.entries(social).length > 0 && Object.entries(social).map((item) => {
                          const [title, url] = item;
                          return (
                            <Link href={url} key={title}>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                className="group cursor-pointer"
                              >
                                <svg
                                  className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white">
                                  <use xlinkHref={`/icons.svg#icon-${title.toLowerCase()}`}></use>
                                </svg>
                              </a>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Rankings