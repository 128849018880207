import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Likes from "../likes";
import Auctions_dropdown from "../dropdown/Auctions_dropdown";
import { useDispatch, useSelector } from "react-redux";
import { buyModalShow } from "../../redux/counterSlice";

const galleryData = [
  // {
  //   id:1,
  //   title: '2022',
  //   featureImage: '/gallery/2021/1.png',
  //   link: '/gallery/2022'
  // },
  {
    id:2,
    title: '2021',
    featureImage: '/gallery/2021/1.png',
    link: '/gallery/2021'
  },
  {
    id:3,
    title: '2020',
    featureImage: '/gallery/2020/1.jpeg',
    link: '/gallery/2020'
  }
]


const GalleryItem = () => {
  return (
    <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
      {galleryData.map((item) => {
        const {
          id,
          featureImage,
          title,
          link
        } = item;
        return (
          <article key={id}>
            <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
              <figure className="relative">
                <Link href={link}>
                    <div className="cursor-pointer">
                      <img
                        src={featureImage}
                        alt="item 5"
                        className="w-full h-[230px] rounded-[0.625rem] object-cover"
                      />
                    </div>
                </Link>
              </figure>
              <div className="mt-7 flex items-center justify-between">
                <Link href={link}>
                    <div className="cursor-pointer">
                      <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                      {title}
                    </span>
                    </div>
                </Link>

              </div>
            </div>
          </article>
        );
      })}
    </div>
  );
};

export default GalleryItem;
