import React, { useContext, useEffect } from "react";
import UserContext from "../components/UserContext";
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { getDocs, query, where, orderBy } from 'firebase/firestore'
import { fsCollection } from '../lib/firebase'
import Meta from '../components/Meta'
import { Bids, Hero, NewseLatter } from '../components/component'
import Rankings from '../components/rankings'
import Galleries from '../components/gallery/Galleries'



export async function getServerSideProps(context) {

  const sponsors = (await getDocs(query(fsCollection('sponsors')))).docs.map( i => ({id: i.id, ...i.data()}));

  return {
    props: { sponsors },
  };
}

export default function Home({sponsors, ...rest}) {
  const { scrollRef } = useContext(UserContext);
  const [data, loading, error] = useCollectionData(query(fsCollection('burger'), where('enabled', '==', true), orderBy('votes', 'desc')));

  const burgers = data;

  if(error){
    console.log(error)
  }
  useEffect(() => {
    window.scrollTo(0, scrollRef.current.scrollPos);
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY;
    };
    window.addEventListener("scroll", handleScrollPos);
    return () => {
      window.removeEventListener("scroll", handleScrollPos);
    };
  });

  if(loading) return null

  if(error){
    console.log(error)
  }
  return (
    <main>
      <Meta title="Landing | Burger Fest 2024" />
      <Hero />
      <Bids sponsors={sponsors} />
      <Rankings contestants={burgers} />
      <Galleries />
      {/*<NewseLatter />*/}
    </main>
  );
}
