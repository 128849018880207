/* eslint-disable @next/next/no-img-element */
import React from "react";
import { trendingCategoryData } from "../../data/categories_data";
import { HeadLine } from "../component";
import GalleryCollection from "./GalleryCollection";

const Galleries= () => {
  return (
    <section className="py-24">
      <div className="container">
        <HeadLine
          text="Galeria"
          classes="font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white"
        />
        <GalleryCollection />
      </div>
    </section>
  );
};

export default Galleries;
